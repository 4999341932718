import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'ranking',
      name: 'input',
      type: 'number',
      label: i18n.t('form.setting.ranking'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'code2',
      name: 'input',
      label: i18n.t('form.setting.countryCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'phone_number_code',
      name: 'input',
      label: i18n.t('form.setting.phone_number_code'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'remote_flag_icon_url',
      name: 'image',
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1
      }
    }]
  }]
}
