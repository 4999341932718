// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getCountryPreview, putCountry } from '@/services/setting.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'hotSearchEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getCountryPreview(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.country
          console.log(this.modifyData)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      // if (prop === 'poi_ids') {
      //   this.getProduct(data)
      // }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.ranking = Number(obj.ranking)
      const params = {
        country: {
          ...obj
        }
      }
      putCountry(this.$route.params.id, params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'countriesList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    afterAdd (creatData, alterData, prop) {
    },
    removeArray (createData, obj) {
    },
    checkDisable (createData) {
    },
    handleSwitchChange (value, createDate, prop) {
    }
  }
}
